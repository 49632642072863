import axios from "axios";
import router from '@/router'

const BackendService = axios.create({
    baseURL: process.env.VUE_APP_API_CUSTOMER,
    withCredentials: true,
    headers: {
        "Content-type": 'application/json',
        "Accept": 'application/json',
    }
})

BackendService.interceptors.request.use(config => {
    let token = ''

    try {
        let userStorage = localStorage.getItem('token')
        token = userStorage
    } catch (error) {
        console.log(error);
    }

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

BackendService.interceptors.response.use(undefined, function (error) {
    if (error) {
        if (error.response.status === 401) {
            return router.push({ path: "/", query: { error_status: error.response.status, text_status: error.response.statusText } });
        }
    }
})

export default BackendService