<template>
  <v-app id="materialpro">
    <!-- :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`" -->
    <router-view />
    <div class="text-center">
      <v-overlay :z-index="zIndex" :value="overlayLoading"  opacity="0.9">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <h2 class="mt-5">Please Wait...</h2>
      </v-overlay>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    zIndex: 205,
  }),
  computed: {
    overlayLoading: {
      set() {
        this.$store.commit("SET_LOADING", false);
      },
      get() {
        return this.$store.getters["getLoading"];
      },
    },
  },
  // mounted() {
  //   let getLocalStorage = localStorage.getItem("user");

  //   if (!getLocalStorage) {
  //     // this.$router.push({ path: "/login" });
  //     return true;
  //   } else {
  //     this.$store.commit("SET_USER", JSON.parse(getLocalStorage));
  //     this.$store.commit("SET_TOKEN", localStorage.getItem("token"));
  //   }
  // },
};
</script>
<style >
.swal2-html-container {
  font-family: "Athiti" !important;
}
.swal2-title {
  font-family: "Athiti" !important;
}
.swal2-styled.swal2-confirm {
  font-family: "Athiti" !important;
}

@import url("https://fonts.googleapis.com/css2?family=Athiti:wght@400;500;600&display=swap");

body {
  font-family: "Athiti", sans-serif;
}
</style>
