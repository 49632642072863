import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from "@/store/store";
import { checkLineUser } from "@/config/lineLiff";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/admin",
      redirect: "/admin/login",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "account",
          component: () => import("@/views/AccountSetting"),
        },
      ],
    },
    {
      path: "",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "login-customer",
          path: "",
          component: () => import("@/views/Customer/Login"),
          beforeEnter: checkLineUser,
        },
        {
          name: "register-customer",
          path: "register",
          component: () => import("@/views/Customer/Register"),
          beforeEnter: (to, from, next) => {
            if (store.state.detail) {
              let detail = store.state.detail;
              // if (detail == "unregister") {
              //   next({
              //     name: "register-customer",
              //     params: {
              //       domain_name: store.state.template.domain_name,
              //     },
              //   });
              //   return;
              // }
              if (detail == "registered") {
                next({
                  name: "survey-customer",
                  params: {
                    domain_name: store.state.template.domain_name,
                  },
                });
                return;
              }
              if (detail == "formed") {
                if (store.state.template.is_register_type == 0) {
                  next({
                    name: "discount-customer",
                  });
                } else {
                  // store.commit("KEEP_REGISTERSTATUS", detail);
                  next();
                }
                return;
              }
              return next();
            } else {
              next();
            }
          },
        },
        {
          name: "rule-customer",
          path: "rule",
          component: () => import("@/views/Customer/Rule"),
          meta: { requiresAuth: true, roleUser: ["Customer"] },
        },
        {
          name: "history-customer",
          path: "history",
          component: () => import("@/views/Customer/History"),
          meta: { requiresAuth: true, roleUser: ["Customer"] },
        },
        {
          name: "menu-customer",
          path: "menu",
          component: () => import("@/views/Customer/Menu"),
          meta: { requiresAuth: true, roleUser: ["Customer"] },
        },
        {
          name: "luckydraw-customer",
          path: "luckydraw",
          component: () => import("@/views/Customer/LuckyDraw"),
          meta: { requiresAuth: true, roleUser: ["Customer"] },
        },
        {
          name: "announcement-customer",
          path: "announcement",
          component: () => import("@/views/Customer/Announcement"),
          meta: { requiresAuth: true, roleUser: ["Customer"] },
        },
        {
          name: "discount-customer",
          path: "discount",
          component: () => import("@/views/Customer/Discount"),
          meta: { requiresAuth: true, roleUser: ["Customer"] },
        },
        {
          name: "survey-customer",
          path: "survey",
          component: () => import("@/views/Customer/Survey"),
          meta: { requiresAuth: true, roleUser: ["Customer"] },
        },
      ],
    },
    {
      path: "/admin",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/Login"),
          meta: {
            roleUser: ["Super Admin", "Admin", "Staff"],
            requiresAuth: false,
          },
        },
        {
          name: "Preview",
          path: "preview",
          component: () => import("@/views/Previews/Preview"),
        },
      ],
    },

    {
      path: "/admin",
      redirect: "admin/microsite",
      component: () => import("@/layouts/full-layout/Layout"),

      children: [
        {
          path: "users",
          component: () => import("@/views/ManageUserTable"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "microsite",
          component: () => import("@/views/MicrositeTable"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "configSms",
          component: () => import("@/views/ConfigSMS"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "configOtp",
          component: () => import("@/views/ConfigOTP"),
          meta: { roleUser: ["Super Admin"] },
        },
      ],
    },
    {
      path: "/admin/page",
      redirect: "/admin/page/upload",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "upload",
          component: () => import("@/views/PageManagement/Upload"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "register",
          component: () => import("@/views/PageManagement/Register"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "menu",
          component: () => import("@/views/PageManagement/Menu"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "announcement",
          component: () => import("@/views/PageManagement/Announcement"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "rule",
          component: () => import("@/views/PageManagement/Rule"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "luckydraw",
          component: () => import("@/views/PageManagement/LuckyDraw"),
          meta: { roleUser: ["Admin"] },
        },
      ],
    },

    {
      path: "/admin/survey/page",
      redirect: "/admin/page/upload",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "upload",
          component: () => import("@/views/PageManagement/Upload"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "register",
          component: () => import("@/views/PageManagement/Register"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "survey",
          component: () => import("@/views/PageManagement/Survey"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "surveyrule",
          component: () => import("@/views/PageManagement/RuleSurvey"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "discount",
          component: () => import("@/views/PageManagement/Discount"),
          meta: { roleUser: ["Admin"] },
        },
      ],
    },
    {
      path: "/admin/backoffice",
      redirect: "/admin/backoffice/dashboard",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "dashboard",
          component: () => import("@/views/BackOffice/Dashboard"),
          meta: {
            roleUser: ["Admin", "Staff"],
          },
        },
        {
          path: "memberlist",
          component: () => import("@/views/BackOffice/MemberList"),
          meta: { roleUser: ["Admin", "Staff"] },
        },
        {
          path: "transaction",
          component: () => import("@/views/BackOffice/Transaction"),
          meta: { roleUser: ["Admin", "Staff"] },
        },
        {
          path: "survey",
          component: () => import("@/views/BackOffice/Survey"),
          meta: { roleUser: ["Admin", "Staff"] },
        },
        {
          path: "uploadcode",
          component: () => import("@/views/BackOffice/CodeUpload"),
          meta: { roleUser: ["Admin"] },
        },
      ],
    },
    {
      path: "/admin/preview",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "register",
          component: () => import("@/views/Previews/RegisterPage"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "uploadimage",
          component: () => import("@/views/Previews/HeaderBgImage"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "menu",
          component: () => import("@/views/Previews/MenuPage"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "announcement",
          component: () => import("@/views/Previews/AnnouncementPage"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "rule",
          component: () => import("@/views/Previews/RulePage"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "luckydraw",
          name: "preview-luckydraw",
          component: () => import("@/views/Previews/LuckyDrawPage"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "survey",
          component: () => import("@/views/Previews/SurveyPage"),
          meta: { roleUser: ["Admin"] },
        },
        {
          path: "discount",
          component: () => import("@/views/Previews/DiscountPage"),
          meta: { roleUser: ["Admin"] },
        },
      ],
    },
    {
      path: "/admin/log",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "logmicrosite",
          component: () => import("@/views/Log/LogMicrosite"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "loguser",
          component: () => import("@/views/Log/LogUser"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "logsms",
          component: () => import("@/views/Log/LogSMS"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "logotp",
          component: () => import("@/views/Log/LogOTP"),
          meta: { roleUser: ["Super Admin"] },
        },
      ],
    },
    {
      path: "/admin/report",
      component: () => import("@/layouts/full-layout/Layout"),
      meta: { requiresAuth: true },
      children: [
        {
          name: "reportotpdetail",
          path: "reportotp/:name/:id",
          component: () => import("@/views/Report/ReportOtpDetail"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          name: "reportsmsdetail",
          path: "reportsms/:name/:id",
          component: () => import("@/views/Report/ReportSmsDetail"),
          meta: { roleUser: ["Super Admin"] },
        },
      ],
    },

    {
      path: "*",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          path: "",
          name: "error",
          component: () => import("@/views/Error"),
        },
      ],
    },
  ],
});

import NProgress from "nprogress";

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let roleUser = store.state.user.role;
    let checkRoleRoutes = to.meta.roleUser
      ? to.meta.roleUser.includes(roleUser)
      : false;

    if (roleUser && checkRoleRoutes) {
      if (store.state.token) {
        next();
      } else {
        next({ name: "login-customer" });
      }
      next();
      return;
    }
    if (!checkRoleRoutes) {
      next({
        name: "error",
        query: { error_status: 401, text_status: "unauthorized" },
      });
      return;
    }

    next({ name: "error" });
  } else {
    next();
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
