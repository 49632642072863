import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import JsonExcel from "vue-json-excel";
import VueQuillEditor from '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

Vue.use(VueSweetalert2);
Vue.use(VueQuillEditor);
//download excel
Vue.component("downloadExcel", JsonExcel);

Vue.use(VueSkycons, {
  color: "#1e88e5",
});
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.prototype.$liff = window.liff

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
