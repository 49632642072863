import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false, // Horizontal layout
    token: {},
    user: {},
    imageMenu: {},
    imageExample: {},
    imageBgLogo: {},
    preview: {},
    setupMenu: {},
    template: {},
    button: {},
    urlPreview: "",
    announcement: {
      listlucky: "",
      announcement: "",
      type: "",
    },
    lineProfile: {},
    loading: false,
    textOver: "",
    textUnder: "",
    isRequestOTP: true,
    detail: "",
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_IMAGE_MENU(state, payload) {
      state.imageMenu = payload;
      // try {
      //   for (const key in payload) {
      //     if (Object.hasOwnProperty.call(payload, key)) {
      //       state.image[key] = payload[key]
      //     }
      //   }
      // } catch (error) {
      //   window.location.reload()
      // }
    },
    SET_IMAGE_EXAMPLE(state, payload) {
      state.imageExample = Object.values(payload)[0];
    },
    SET_IMAGE_BACKGROUND_LOGO(state, payload) {
      state.imageBgLogo = payload;
    },
    SET_PREVIEW(state, payload) {
      state.preview = payload;
    },
    SET_COLOR_BUTTON(state, payload) {
      state.button = payload;
    },
    SETUP_MENU(state, payload) {
      state.setupMenu = payload;
    },
    SET_ANNOUNCEMENT(state, payload) {
      state.announcement = payload;
    },
    SET_RULE(state, payload) {
      state.rule = payload;
    },
    SET_TERM(state, payload) {
      state.term = payload;
    },
    SET_IMAGE_RULE(state, payload) {
      state.ruleImage = payload;
    },
    KEEP_TEMPLATE(state, payload) {
      state.template = payload;
    },
    SET_URL(state, payload) {
      state.urlPreview = payload.url;
    },
    KEEP_LINE_INFO(state, payload) {
      state.lineProfile = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_TEXT_DISCOUNT(state, payload) {
      state.textOver = payload.text_over;
      state.textUnder = payload.text_under;
    },
    SET_IS_REQUEST_OTP(state, payload) {
      state.isRequestOTP = payload;
    },
    SET_DETAIL(state, payload) {
      state.detail = payload;
    },
  },
  actions: {},
  getters: {
    getLoading(state) {
      if (state.loading) {
        return state.loading;
      } else {
        return false;
      }
    },
    isAuthenticated(state) {
      if (state.token) {
        return state.token;
      }
    },
    isRole(state) {
      if (state.user) {
        return state.user.role;
      } else {
        return "Customer";
      }
    },
  },
});
