import liff from "@line/liff";
import Axios from "@/config/backend-customer";
import store from "@/store/store";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const checkLineUser = async (to, from, next) => {
  try {
    const domain = window.location.toString().split("?");
    if (domain[0] === "https://mkt.in.th/") {
      next({
        name: "Login",
      });
      return;
    }
    Axios.post("/getMicrositeInfo ", {
      domain_name: domain[0],
    })
      .then((res) => {
        const responseData = res.data;
        if (res.data.Info === null) {
          next({
            name: "error",
          });
        }
        if (responseData.message == "Success") {
          if (res.data.Info.status == "inactive") {
            Swal.fire({
              icon: "error",
              title: "ขออภัยค่ะ",
              text: "สิ้นสุดระยะเวลาร่วมกิจกรรมแล้ว",
              showConfirmButton: true,
              confirmButtonColor: "red",
              confirmButtonText: "ตกลง",
            });
            next({
              name: "error",
            });
            return;
          }

          store.commit("KEEP_TEMPLATE", res.data.Info);

          if (responseData.Info.microsite_type == "Survey") {
            LineLogin();
          } else {
            next();
          }
        } else {
          next({
            name: "error",
          });
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });

    const LineLogin = async () => {
      await liff.init({ liffId: store.state.template.liff_id }).catch((err) => {
        throw err;
      });

      if (!liff.isLoggedIn()) {
        liff.login({
          redirectUri: window.location.href,
        });
      }

      const profile = await liff.getProfile();
      if (profile) {
        store.commit("KEEP_LINE_INFO", profile);
        const payload = {
          line_id: profile.userId,
          microsite_id: store.state.template.microsite_id,
        };
        const { data } = await Axios.post("survey/checkLineRegister", payload);

        store.commit("SET_DETAIL", data.detail);

        if (data.detail == "unregister") {
          next({
            name: "register-customer",
            params: {
              domain_name: store.state.template.domain_name,
            },
          });
          return;
        }
        if (data.detail == "registered") {
          localStorage.setItem("token", data.token);
          store.commit("SET_TOKEN", data.token);
          store.commit("SET_USER", { role: "Customer" });
          next({
            name: "survey-customer",
            params: {
              domain_name: store.state.template.domain_name,
            },
          });
          return;
        }
        if (data.detail == "formed") {
          localStorage.setItem("token", data.token);
          store.commit("SET_TOKEN", data.token);
          store.commit("SET_USER", { role: "Customer" });

          if (store.state.template.is_register_type == 0) {
            return next({
              name: "discount-customer",
            });
          } else {
            // store.commit("KEEP_REGISTERSTATUS", data.detail);
            return next({
              name: "register-customer",
            });
          }
        }
      } else {
        return;
      }
    };
  } catch (err) {
    console.log(`liff.state init error ${err}`);
  }
};
