import Vue from "vue";
import "@/scss/vuetify/overrides.scss";
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VCombobox,
  VSelect,
  VTextarea
} from 'vuetify/lib';
import { Ripple, Intersect, Touch, Resize } from 'vuetify/lib/directives';
Vue.use(Vuetify, {
  components: { VCombobox, VTextarea, VRow, VTooltip, VCol, VTextField, VCheckbox, VSelect },
  directives: { Ripple, Intersect, Touch, Resize },
});

const theme = {
  primary: "#1e88e5", // change header color from here || "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  info: "#1e88e5",
  success: "#21c1d6",
  accent: "#fc4b6c",
  default: "#563dea",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
    dark: false, // If you want to set dark theme then dark:true else set to false
  },
  icons: {
    iconfont: 'mdi'// || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg' ||'mdiSvg'
  },
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280,
    },
  },
  rtl: false, // If you want to set rtl theme then rtl:true else set to false
});
